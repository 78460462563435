<template>
  <div id="user-management">
    <v-row no-gutters>
      <v-col cols="1" md="0" sm="0" class="left-spacer">&nbsp;</v-col>
      <v-col cols="12" class="user-page-title main-content">
        <span>Create New User</span>
      </v-col>
      <v-col cols="12" md="0" sm="0" />
    </v-row>
    <v-row no-gutters id="user-form">
      <v-col cols="1" md="0" sm="0" class="left-spacer">&nbsp;</v-col>
      <v-col cols="12" md="12" class="main-content">
        <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card class="box-height" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Username</p>
                      <v-text-field
                        v-model="userName"
                        required
                        autocomplete="off"
                        :rules="userNameRules"
                        placeholder="New Username"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters style="margin-top: 2px">
                    <v-col cols="12" md="12">
                      <p class="label">Full Name</p>
                      <v-text-field
                        v-model="fullName"
                        required
                        autocomplete="off"
                        :rules="fullNameRules"
                        placeholder="New User Full Name"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters style="margin-top: 2px">
                    <v-col cols="12" md="12">
                      <p class="label">User Group Type</p>
                      <v-select
                        v-model="userGroup"
                        :items="groups"
                        item-text="name"
                        item-value="id"
                        :rules="userGroupRules"
                        filled
                        placeholder="Select User Group Type"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card class="box-height" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Email Address</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        placeholder="New User Email Address"
                        required
                        autocomplete="off"
                        :rules="emailRules"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters style="margin-top: 2px">
                    <v-col cols="12" md="12">
                      <p class="label">Phone Number</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <phone-number-mask
                        v-model="phoneNum"
                        :rules="phoneNumRules"
                        filled
                        v-bind:options="{
                          humanMask: '(+##) #####-####',
                          machineMask: '###########',
                          empty: null,
                          applyAfter: false,
                          alphanumeric: true,
                          lowerCase: false,
                          placeholder: 'Phone Number',
                          rules: phoneNumRules,
                        }"
                      ></phone-number-mask>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card class="box-height" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Password</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="password"
                        placeholder="Password"
                        required
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPass ? 'text' : 'password'"
                        @click:append="showPass = !showPass"
                        autocomplete="off"
                        :rules="passwordRules"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Confirm Password</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="confirmPass"
                        placeholder="Confirm Password"
                        required
                        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirm ? 'text' : 'password'"
                        @click:append="showConfirm = !showConfirm"
                        autocomplete="off"
                        :rules="confirmPassRules"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters style="margin-top: 52px; max-width: 387px"> -->
                  <!-- <v-spacer></v-spacer> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer />
            <v-col cols="12" md="4">
              <div class="button-wrapper">
                <v-btn
                  :ripple="false"
                  :disabled="!valid"
                  type="submit"
                  class="button-adduser"
                >
                  <v-img
                    alt="Save"
                    class="shrink"
                    contain
                    src="../../assets/pages/button_add_user.svg"
                    transition="scale-transition"
                    eager
                  ></v-img>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="1" md="0" sm="0" />
    </v-row>
    <v-row no-gutters class="table-section">
      <v-col cols="1" md="0" sm="0" class="left-spacer">&nbsp;</v-col>
      <v-col cols="12" class="user-page-title main-content">
        <span>View Users</span>
      </v-col>
      <v-col cols="1" md="0" sm="0"></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="1" md="0" sm="0" class="left-spacer">&nbsp;</v-col>
      <v-col cols="12" class="main-content">
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="pageSize"
          :search="search"
          @pagination="updatePagination"
          no-data-text="No Data"
          :footer-props="footer"
        >
          <template v-slot:top>
            <v-row no-gutters id="table-search">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  placeholder="Search Users"
                  class="search-table"
                  append-icon="mdi-magnify"
                  autocomplete="off"
                  rounded
                  outlined
                  filled
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
          </template>
          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 + (currentPage - 1) * pageSize }}
          </template>
          <template v-slot:[`item.profileImg`]="{ item }">
            <v-avatar size="50">
              <img
                v-if="item.profileImg"
                alt="Profile Picture"
                class="shrink mr-2"
                contain
                :src="item.profileImg"
                transition="scale-transition"
                width="41"
                height="41"
              />
              <v-img
                v-else
                alt="Profile Picture"
                class="shrink mr-2"
                contain
                src="../../assets/pages/icon_user_default_display.svg"
                transition="scale-transition"
                min-width="41"
                min-height="41"
              />
            </v-avatar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :ripple="false" icon class="mr-3">
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/button_key_white.svg"
                transition="scale-transition"
                @click="showNewPassDialog(item)"
              />
            </v-btn>
            <v-btn :ripple="false" icon v-if="item.status == 'locked'" class="mr-3">
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/button_lock.svg"
                transition="scale-transition"
                @click="
                  openConfirmDialog(item, unlockUser, 'You are about to unlock user')
                "
              />
            </v-btn>
            <v-btn :ripple="false" icon class="mr-3">
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/Button_edit.svg"
                transition="scale-transition"
                @click="openDialog(item)"
              />
            </v-btn>
            <v-btn :ripple="false" icon>
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/Button_delete.svg"
                transition="scale-transition"
                @click="
                  openConfirmDialog(item, deleteUser, 'You are about to delete user')
                "
              />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="0" sm="0" />
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      content-class="user-edit-dialog"
      max-width="1000"
    >
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-card outlined tile>
                  <v-card-text class="box-height">
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label">Username</p>
                        <v-text-field
                          v-model="editUsername"
                          required
                          autocomplete="off"
                          :rules="userNameRules"
                          placeholder="New Username"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters style="margin-top: 2px">
                      <v-col cols="12" md="12">
                        <p class="label">Full Name</p>
                        <v-text-field
                          v-model="editFullName"
                          required
                          autocomplete="off"
                          :rules="fullNameRules"
                          placeholder="New User Full Name"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters style="margin-top: 2px">
                      <v-col cols="12" md="12">
                        <p class="label">User Group Type</p>
                        <v-select
                          v-model="editUserGroup"
                          :items="groups"
                          item-text="name"
                          item-value="id"
                          :rules="userGroupRules"
                          filled
                          placeholder="Select User Group Type"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined tile>
                  <v-card-text class="box-height">
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label">Email Address</p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="editEmail"
                          placeholder="New User Email Address"
                          required
                          autocomplete="off"
                          :rules="emailRules"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters style="margin-top: 2px">
                      <v-col cols="12" md="12">
                        <p class="label">Phone Number</p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <!-- <v-text-field
                          v-model="editPhoneNum"
                          placeholder="Phone Number"
                          required
                          autocomplete="off"
                          type="number"
                          :rules="phoneNumRules"
                          filled
                        ></v-text-field> -->
                        <phone-number-mask
                          v-model="editPhoneNum"
                          :rules="phoneNumRules"
                          filled
                          v-bind:options="{
                            humanMask: '(+##) #####-####',
                            machineMask: '###########',
                            empty: null,
                            applyAfter: false,
                            alphanumeric: true,
                            lowerCase: false,
                            placeholder: 'Phone Number',
                            rules: phoneNumRules,
                          }"
                        ></phone-number-mask>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-3">
                      <v-col cols="12" md="12" class="text-right">
                        <v-btn
                          class="cancel-button form-btn"
                          :ripple="false"
                          @click="closeDialog"
                          >Cancel</v-btn
                        >
                        <v-btn
                          style="margin-right: 30px"
                          class="form-btn"
                          :ripple="false"
                          @click="revertEdit"
                          >Revert</v-btn
                        >
                        <v-btn
                          class="form-btn"
                          :ripple="false"
                          type="submit"
                          :disabled="!valid"
                          >Save</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="700px"
      content-class="user-edit-dialog"
    >
      <v-card>
        <v-card-title>Confirm</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="7" style="padding-top: 6px">
              <span>{{ dialogMessage + " " + userItem.userName }}</span>
            </v-col>
            <v-col cols="12" md="5" class="text-right">
              <v-btn
                class="cancel-button form-btn"
                :ripple="false"
                @click="closeConfirmDialog"
                >CANCEL</v-btn
              >
              <v-btn class="form-btn" :ripple="false" @click="dialogAction(userItem)"
                >YES</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="changePassDialog"
      persistent
      max-width="600"
      content-class="user-edit-dialog"
    >
      <v-card>
        <v-card-title>CHANGE PASSWORD</v-card-title>
        <v-card-text>
          <v-form ref="editPass" @submit.prevent="submitPass" v-model="passValid">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-right pr-2 pt-4">
                <span>New Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="newPassword"
                  required
                  :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPass ? 'text' : 'password'"
                  @click:append="showNewPass = !showNewPass"
                  autocomplete="off"
                  :rules="passwordRules"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-right pr-2 pt-4">
                <span>Confirm Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="confirmNewPass"
                  required
                  :append-icon="showConfirmNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmNewPass ? 'text' : 'password'"
                  @click:append="showConfirmNewPass = !showConfirmNewPass"
                  autocomplete="off"
                  :rules="newConfirmPassRules"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters style="margin-top: 20px">
              <v-col cols="12" md="12">
                <span>Are you sure you want to change the password?</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-spacer></v-spacer>
              <v-col cols="12" md="6" class="text-right">
                <v-btn
                  class="cancel-button form-btn"
                  :ripple="false"
                  @click="closePassDialog"
                  >CANCEL</v-btn
                >
                <v-btn
                  class="form-btn"
                  :ripple="false"
                  type="submit"
                  :disabled="!passValid"
                  >YES</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" md="9" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn class="form-btn" :ripple="false" @click="closeMessage">OK</v-btn>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="../../assets/css/user-management.scss"></style>
<script>
import { groupService, userService, getImageUrl, formatPhoneNum } from "../../services";
import { footerProps } from "../../contants";
import PhoneNumberMask from "../../components/PhoneNumberMask.vue";
import _ from "lodash";
export default {
  components: {
    PhoneNumberMask,
  },
  data() {
    return {
      showConfirm: false,
      showPass: false,
      userName: "",
      fullName: "",
      userGroup: "",
      email: "",
      phoneNum: "",
      password: "",
      confirmPass: "",
      editUsername: "",
      editFullName: "",
      editUserGroup: "",
      editEmail: "",
      editPhoneNum: "",
      userInfo: null,
      search: "",
      newPassword: "",
      confirmNewPass: "",
      showNewPass: false,
      showConfirmNewPass: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      total: 0,
      headers: [
        { text: "No", value: "no", sortable: false, filterable: false },
        { text: "", value: "profileImg", sortable: false, filterable: false },
        { text: "Full Name", value: "fullName" },
        { text: "User Name", value: "userName" },
        { text: "Email Address", value: "email" },
        { text: "Phone No", value: "phoneNum" },
        { text: "User Group", value: "group" },
        { text: "Registered", value: "created_at" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      users: [],
      groups: [],
      pageSize: 3,
      currentPage: 1,
      valid: false,
      userNameRules: [
        (v) => !!v || "User Name is required",
        (v) => /^\S+$/.test(v) || "User Name must not include whitespace",
      ],
      fullNameRules: [(v) => !!v || "User Full Name is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneNumRules: [(v) => !!v || "Phone Number is required"],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v?.length >= 8 || "Password length must be greater than 7",
      ],
      userGroupRules: [(v) => !!v || "User Group is required"],
      dialog: false,
      footer: {},
      confirmDialog: false,
      userItem: { userName: "" },
      changePassDialog: false,
      passValid: false,
      dialogAction: () => null,
      dialogMessage: "",
    };
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
    this.getListGroups().then(() => {
      this.getListUsers();
    });
  },
  methods: {
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(this.footer, "pageText", `${this.currentPage} of ${this.total}`);
    },
    getListGroups() {
      return groupService
        .getGroupList()
        .then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            data.map((val) => {
              if (val.permissions) {
                val.allowAdd = !!val.permissions["users.create"];
                val.allowEdit = !!val.permissions["users.edit"];
                val.allowDelete = !!val.permissions["users.delete"];
              }
            });
            this.groups = [...data]; //Object.assign([], this.groups, data);
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    getListUsers() {
      userService.getListUsers().then((res) => {
        if (res?.data?.data?.length) {
          const data = res.data.data;
          data.map((val) => {
            val.fullName = val.full_name;
            val.phoneNum = formatPhoneNum(val.phone_number);
            val.profileImg = val.profile_image ? getImageUrl(val.profile_image) : null;
            val.userName = val.username;
            val.group = this.getGroupName(val.user_group_id);
          });
          this.users = [...data]; //Object.assign([], this.users, data);
          this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
        }
      });
    },
    getGroupName(groupId) {
      if (!this.groups?.length || !groupId) {
        return "";
      }
      const group = _.find(this.groups, (obj) => obj.id == groupId);
      return group?.name || "";
    },
    submit() {
      const data = this.prepareData(
        this.userName,
        this.fullName,
        this.userGroup,
        this.email,
        this.phoneNum,
        this.password
      );
      userService
        .storeUser(data)
        .then(() => {
          this.getListUsers();
          this.revert();
          this.apiMessage = "User has been successfully created";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "User cannot be created";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    revert() {
      this.$refs.form.reset();
    },
    prepareData(userName, fullName, userGroup, email, phoneNum, password) {
      const data = {
        username: userName,
        full_name: fullName,
        user_group_id: userGroup,
        email,
        phone_number: phoneNum,
        proifle_image: null,
      };
      if (password) {
        data.password = password;
      }
      return data;
    },
    unlockUser(item) {
      if (item?.id) {
        userService
          .unlockUser(item.id)
          .then(() => {
            this.userItem = Object.assign({}, this.userItem, { userName: "" });
            this.closeConfirmDialog();
            this.getListUsers();
            this.apiMessage = "User has been successfully unlocked";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User cannot be unlocked";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    openDialog(item) {
      if (item?.id) {
        userService
          .getUser(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setUserInfo(res.data.data);
              this.dialog = true;
            }
          })
          .catch();
      }
    },
    closeDialog() {
      this.setUserInfo(null);
      this.dialog = false;
    },
    openConfirmDialog(item, action, message) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.dialogAction = action;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogMessage = "";
    },
    revertEdit() {
      this.setUserInfo(this.userInfo);
    },
    setUserInfo(data) {
      if (data) {
        this.editFullName = data.full_name || "";
        this.editUserGroup = data.user_group_id || "";
        this.editPhoneNum = data.phone_number || "";
        this.editUsername = data.username || "";
        this.editEmail = data.email || "";
        this.userInfo = data;
      } else {
        this.editFullName = "";
        this.editUserGroup = "";
        this.editPhoneNum = "";
        this.editUsername = "";
        this.editEmail = "";
        this.userInfo = null;
      }
    },
    submitEdit() {
      const data = this.prepareData(
        this.editUsername,
        this.editFullName,
        this.editUserGroup,
        this.editEmail,
        this.editPhoneNum
      );
      userService
        .updateUser(this.userInfo.id, data)
        .then(() => {
          this.closeDialog();
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.messageDialog = true;
          this.getListUsers();
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    deleteUser(item) {
      if (item?.id) {
        userService
          .deleteUser(item.id)
          .then(() => {
            this.closeConfirmDialog();
            this.apiMessage = "User has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUsers();
          })
          .catch((err) => {
            let message = "User cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    showNewPassDialog(item) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.changePassDialog = true;
    },
    closePassDialog() {
      this.$refs.editPass.reset();
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.changePassDialog = false;
    },
    submitPass() {
      console.log("submitpass");
      const data = {
        new_password: this.newPassword,
      };
      userService
        .resetPassForUser(this.userItem.id, data)
        .then(() => {
          this.closePassDialog();
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
  computed: {
    confirmPassRules() {
      return [this.confirmPass === this.password || "Password must match"];
    },
    newConfirmPassRules() {
      return [this.newPassword === this.confirmNewPass || "Password must match"];
    },
  },
};
</script>
